import logo_img from "../assets/favicon_SHI_up_v2.jpg";
import Box from "@mui/material/Box";
import { Link, useParams } from "react-router-dom";
import data from "../data/blogs/index.json";
import styled from "styled-components";

import { useState, useEffect } from "react"
import Markdown from "markdown-to-jsx"
import Code from "./utils/Code"

import '../styles/blog.css'

const MarkdownImg = styled.img`
  width: 100%;
`;


const Nav = () => (
  <Box
    id="menu"
    className="navbar navbar-default navbar-fixed-top"
    sx={{ zIndex: 999 }}
  >
    <div className="container">
      <div className="navbar-header">
        <button
          type="button"
          className="navbar-toggle collapsed"
          data-toggle="collapse"
          data-target="#bs-example-navbar-collapse-1"
        >
          {" "}
          <span className="sr-only">Toggle navigation</span>{" "}
          <span className="icon-bar"></span> <span className="icon-bar"></span>{" "}
          <span className="icon-bar"></span>{" "}
        </button>
        <Link to="/">
          <img
            src={logo_img}
            className="img-responsive"
            style={{ height: "50px" }}
            alt=""
          />{" "}
        </Link>{" "}
      </div>

      <div
        className="collapse navbar-collapse"
        id="bs-example-navbar-collapse-1"
      >
        <ul className="nav navbar-nav navbar-right">
          <li>
            <Link to="/">Home</Link>
          </li>
        </ul>
      </div>
    </div>
  </Box>
);



export const Blog = (props) => {
  const [postContent, setPostcontent] = useState('')
  const [isDark, setIsDark] = useState(true)

  let { blog_path } = useParams();
  let blog = data.filter((elem)=>
    elem.url_title === blog_path
  )[0]

  useEffect(() => {
    import(`../data/blogs/md_files/${blog.md_file}`)
      .then(res =>
        fetch(res.default)
          .then(response => response.text())
          .then(response => setPostcontent(response))
          .catch(err => console.log(err))
      )  

    // import(`../../public/img/blogs/${blog_path}/${blog.cover_img}`)
    //   .then((image) => setCover(image.default))
    //   .catch((err) => console.error('Error loading image:', err));      
  }, [blog])

  return (
    <>
      <Nav />
      <main className='main'>
      <div className="article-wrapper">
      <article>
        {/* <header>
          <div className="article__cover">
            <img
              src={cover}
              alt="my-cover"
            />
          </div>
        </header> */}
        <main>
          <Markdown
            options={{
              overrides: {
                Code: {
                  component: Code,
                  props: {
                    isDark,
                    setIsDark
                  }
                },
                img: {
                  component: MarkdownImg,
                  props: {}
                }
              }
            }}
          >
            {postContent}
          </Markdown>
        </main>
      </article>
    </div>
      </main>
    </>    

  )
}