import * as React from "react";
import logo_img from "../assets/favicon_SHI_up_v2.jpg";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import data from "../data/blogs/index.json";
import { ScrollRestoration } from "react-router-dom";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";

const Nav = () => (
    <Box
        id="menu"
        className="navbar navbar-default navbar-fixed-top"
        sx={{ zIndex: 999 }}
    >
        <div className="container">
            <div className="navbar-header">
                <button
                    type="button"
                    className="navbar-toggle collapsed"
                    data-toggle="collapse"
                    data-target="#bs-example-navbar-collapse-1"
                >
                    {" "}
                    <span className="sr-only">Toggle navigation</span>{" "}
                    <span className="icon-bar"></span>{" "}
                    <span className="icon-bar"></span>{" "}
                    <span className="icon-bar"></span>{" "}
                </button>
                <Link to="/">
                    <img
                        src={logo_img}
                        className="img-responsive"
                        style={{ height: "50px" }}
                        alt=""
                    />{" "}
                </Link>{" "}
            </div>

            <div
                className="collapse navbar-collapse"
                id="bs-example-navbar-collapse-1"
            >
                <ul className="nav navbar-nav navbar-right">
                    <li>
                        <Link to="/">Home</Link>
                    </li>
                </ul>
            </div>
        </div>
    </Box>
);

export const Blogs = (props) => {

    const [list, setList] = React.useState(data);

    return (
        <>
            <Nav />
            <Box id="publications" className="text-center" sx={{ mt: "80px" }}>
                <div className="container">
                    <Box className="section-title" sx={{ mb: "30px" }}>
                        <h2>Blogs</h2>
                    </Box>
                    <div className="row">
                        <List
                            sx={{
                                width: "100%",
                                maxWidth: 960,
                                margin: "auto",
                                bgcolor: "background.paper",
                            }}
                        >
                            {list.map((d, i) => (
                                <div key={`${d.name}-${i}`}>
                                    <ListItem alignItems="flex-start">
                                        <ListItemAvatar
                                            sx={{ mt: "4px", mr: "10px" }}
                                        >
                                            <Avatar
                                                alt={d.title}
                                                src={d.thumbnail}
                                                sx={{ width: 50, height: 50 }}
                                                variant="square"
                                            />
                                        </ListItemAvatar>
                                        <div>
                                            <Link to={`${d.url_title}`}>
                                                <Typography>
                                                    {d.title}
                                                </Typography>
                                            </Link>

                                            <Typography
                                                sx={{ fontSize: "0.9rem" }}
                                            >
                                                {d.date}
                                            </Typography>
                                        </div>
                                    </ListItem>
                                    {i < list.length - 1 && (
                                        <Divider
                                            variant="middle"
                                            component="li"
                                        />
                                    )}
                                </div>
                            ))}
                        </List>
                    </div>
                </div>
            </Box>
            <ScrollRestoration />
        </>
    );
};
